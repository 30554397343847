<template>
  <div class="container" ref="container">
    <el-row :gutter="20" class="query-form" ref="queryForm">
      <el-col :span="24">
        <el-alert
          title="请按提示筛选数据，仅支持中文简体输入法！"
          type="success"
          :show-icon="true"
        >
        </el-alert>
      </el-col>
      <el-col :span="24">
        <div class="top-panel">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="学号">
              <el-input
                class="el-input-normal-size"
                v-model="formInline.number"
                placeholder="请输入学号"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input
                class="el-input-small-size"
                v-model="formInline.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别" v-show="!formCollapse">
              <el-select
                class="el-input-small-size"
                v-model="formInline.gender"
                placeholder="选择性别"
              >
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="教师工号" v-show="!formCollapse">
              <el-input
                class="el-input-normal-size"
                v-model="formInline.tNumber"
                placeholder="请输入教师工号"
              ></el-input>
            </el-form-item>
            <el-form-item label="教师姓名" v-show="!formCollapse">
              <el-input
                class="el-input-normal-size"
                v-model="formInline.tName"
                placeholder="请输入教师姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否上线" v-show="!formCollapse">
              <el-select
                class="el-input-small-size"
                v-model="formInline.online"
                placeholder="选择是否"
              >
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="周几" v-show="!formCollapse">
              <el-select
                class="el-input-small-size"
                v-model="formInline.week"
                placeholder="选择周几"
              >
                <el-option v-for="item in weekList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否反馈" v-show="!formCollapse">
              <el-select
                class="el-input-small-size"
                v-model="formInline.reback"
                placeholder="选择是否"
              >
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" v-show="!formCollapse">
              <el-select
                class="el-input-small-size"
                v-model="formInline.statusStr"
                placeholder="选择状态"
              >
                <el-option label="已通过" value="已通过"></el-option>
                <el-option label="待审批" value="待审批"></el-option>
                <el-option label="待咨询" value="待咨询"></el-option>
                <el-option label="已取消" value="已取消"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="onSubmit"
                >查询</el-button
              >
              <el-button
                type="primary"
                plain
                @click="onCollapse"
                class="button-div"
                >合并<i
                  :class="[
                    formCollapse ? 'el-icon-arrow-down' : 'el-icon-arrow-up',
                    'el-icon--right',
                  ]"
                ></i
              ></el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="left-panel">
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            添加
          </el-button>
          <el-button icon="el-icon-delete" type="danger" @click="handleDelete">
            删除
          </el-button>
          <el-button icon="el-icon-edit" type="success" @click="handleEdit">
            修改
          </el-button>
          <el-button
            icon="el-icon-download"
            type="warning"
            @click="handleExport"
          >
            导出
          </el-button>
        </div>
      </el-col>
    </el-row>

    <el-table
       ref="multipleTable" 
       @selection-change="handleSelectionChange"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :height="height"
      :key="timeStamp"
      :header-cell-style="{
        background: '#EBEEF5',
        'border-right': '1px solid #dddddd',
      }"
      align="center"
    >
      <el-table-column
        show-overflow-tooltip
        type="selection"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column label="序号" type="index" width="50" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="number" label="学号" width="110" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="name" label="姓名" align="center" width="90" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="gender" label="性别" width="50" align="center">
      </el-table-column>
      <el-table-column prop="academy" label="院系名称" width="110" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="major" label="专业" width="100" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="content" label="预约内容" align="center" min-width="200" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="预约状态" align="center" width="80">
        <template slot-scope="scope">
          <el-link :underline="false" :class="scope.row.status">{{
            scope.row.statusStr
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="time" label="时间" align="center" width="160" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200px" fixed="right">
        <template slot-scope="scope">
          <el-button size="medium" style="margin: 0 15px;" type="text" @click="handleDetail(scope.row)">详情</el-button>
          <el-button size="medium" style="margin: 0 15px;" type="text" @click="handleEdit(scope.row)">编辑</el-button>
          <el-popover trigger="hover" placement="top">
            <el-button slot="reference" size="medium" style="margin: 0 15px;" type="text">...</el-button>
            <el-button size="medium" style="margin: 0 15px;" type="text" @click="handleDetail(scope.row)">详情</el-button>
            <el-button size="medium" style="margin: 0 15px;" type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="medium" style="margin: 0 15px; color: red;" type="text" @click="handleDelete(scope.row)">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="currentPage"
      :page-size="pageSize"
      @current-change="currentChange"
    >
    </el-pagination>

    <el-dialog title="添加" :visible.sync="addDialogFormVisible" :width="dialogWidth">
        <el-form :model="editDialogForm" label-position="right" label-width="80px" class="demo-form-inline">
            <el-form-item label="学号">
              <el-input
                v-model="editDialogForm.number"
                placeholder="请输入学号"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input
                v-model="editDialogForm.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-select
                v-model="editDialogForm.gender"
                placeholder="选择性别"
              >
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="editDialogForm.statusStr"
                placeholder="选择状态"
              >
                <el-option label="已通过" value="已通过"></el-option>
                <el-option label="待审批" value="待审批"></el-option>
                <el-option label="待咨询" value="待咨询"></el-option>
                <el-option label="已取消" value="已取消"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addDialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitAdd">确 定</el-button>
        </div>
    </el-dialog>

  </div>
</template>

<script>
import { studentAppointmentList, addStuAppointment } from '@/api/index'
import { mapGetters } from 'vuex';
export default {
  name: 'studentList',
  data() {
    return {
      formInline: {},
      formCollapse: false,
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      multipleSelection: [],
      timeStamp: 0,
      height: '150px',
      weekList: [
        {
            label: '周一',
            value: 1
        },
        {
            label: '周二',
            value: 2
        },
        {
            label: '周三',
            value: 3
        },
        {
            label: '周四',
            value: 4
        },
        {
            label: '周五',
            value: 5
        },
        {
            label: '周六',
            value: 6
        },
        {
            label: '周日',
            value: 7
        },
      ],
      addDialogFormVisible: false,
      editDialogForm: {}
    };
  },
  computed: {
    ...mapGetters({
        device: "settings/device",
    }),
    dialogWidth() {
        if(this.device === "mobile") {
            return '90%'
        } else {
            return '50%'
        }
    }
  },
  mounted() {
    this.height = this.$tableHeight(50, '.query-form')
    window.addEventListener('resize', () => {
        this.height = this.$tableHeight(50, '.query-form')
    })
    this.fetchData()
    console.log('mounted');
  },
  destroyed() {
    window.removeEventListener("resize", () => {
        // console.log(`卸载resize`);
    });
  },
  activated() {
    console.log('activated');
  },
  deactivated() {
    console.log('deactivated');
  },
  methods: {
    
    fetchData(refresh) {
        if(refresh) {
            this.currentPage = 1
        }
        this.getStudentAppointmentList()
    },
    async getStudentAppointmentList() {
        const s = {
            '已通过': 'primary',
            '待审批': 'warning',
            '待咨询': 'success',
            '已取消': 'danger'
        }
        let { currentPage, pageSize } = this
        let res = await studentAppointmentList({ pageNum: parseInt(currentPage), pageSize })
        
        if(res && res.code == 200) {
            let { data } = res
            data = data.map(item => {
                return {
                    ...item,
                    status: s[item.statusStr]
                }
            })
            this.tableData = data
            this.total = res.total
        }
        this.timeStamp++
    },
    currentChange(e) {
        this.currentPage = e
        this.getStudentAppointmentList()
    },
    onSubmit() {
        this.currentPage = 1
        console.log(this.formInline);
    },
    onCollapse() {
      this.formCollapse = !this.formCollapse;
      let that = this;
      this.$nextTick(() => {
        that.height = that.$tableHeight(50, '.query-form')
      })
    },
    handleSelectionChange(val) {
        console.log(val)
        this.multipleSelection = val
    },
    handleAdd(e) { 
        this.addDialogFormVisible = true
    },
    async submitAdd() {
        let { editDialogForm } = this
        let res = await addStuAppointment({...editDialogForm})
        if(res && res.code == 200) {
            this.fetchData(true)
            this.addDialogFormVisible = false
        }
    },
    handleDelete(e) { console.log("handleDelete", e); },
    handleEdit(e) { console.log("handleEdit", e); },
    handleExport(e) { console.log("handleExport", e); },
    handleDetail(e) { console.log("handleDetail", e); },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;
  .query-form{
    margin-bottom: 15px;
  }
  .top-panel {
    min-height: 32px;
    margin: 15px 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    ::v-deep {
      .el-form-item {
        margin-left: 20px;
      }
      .el-form-item:last-child {
        margin-left: 40px;
      }
    }
  }
  .left-panel{
    &::v-deep {
        .el-button {
            margin: 0 10px 10px 0!important;
        }
    }
  }
  .el-input-small-size {
    width: 150px;
  }
  .el-input-normal-size {
    width: 250px;
  }
  /* 解决按钮点击后任然保持点击态问题 */
  /*鼠标点击后移开，恢复本身样式*/
  .button-div,
  .button-div:focus:not(.button-div:hover) {
    margin-right: 12px;
    border: 1px solid #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 #fff;
    color: var(--color-primary);
    background: #fff;
  }
  /*鼠标悬浮，没有按下；鼠标按下后抬起，没有移开*/
  .button-div:focus,
  .button-div:active,
  .button-div:hover {
    background: #f5f7fa;
    border: 1px solid #fff !important;
    color: var(--color-primary);
  }
  /*鼠标按下，没有抬起*/
  .button-div:active {
    /* background: var(--color-primary);
        color: white; */
  }

   .el-link {
        &.primary{
            color: $--color-primary;
        }
        &.warning{
            color: $--color-warning;
        }
        &.danger{
            color: $--color-danger;
        }
        &.success{
            color: $--color-success;
        }
    }
}
</style>