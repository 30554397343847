import request from '@/utils/request'

export function studentAppointmentList(data) {
    return request({
        url: '/studentAppointmentList',
        method: 'post',
        data
    })
}

export function addStuAppointment(data) {
    return request({
        url: '/addStuAppointment',
        method: 'post',
        data
    })
}

export function collegesList(data) {
    return request({
        url: '/collegesList',
        method: 'post',
        data
    })
}
